import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ResourcePage from '../../components/industry-resources/ResourcePage'
import SEO from '../../components/SEO'
import ResourceItem from '../../components/industry-resources/ResourceItem'

const ErudusLogosBadgesPage = () => {
  const { markdownRemark, printResource } = useStaticQuery(graphql`
    query ErudusLogosBadgesPageQuery {
      markdownRemark(
        frontmatter: { path: { eq: "/industry-resources/erudus-logos-badges" } }
      ) {
        frontmatter {
          title
          path
          seoImage {
            ...SeoImageSharpFragment
          }
        }
      }
      printResource: allDatoCmsPrintResourceLogoBadge(
        sort: { fields: position }
      ) {
        edges {
          node {
            title
            descriptionNode {
              childMarkdownRemark {
                html
              }
            }
            isDownload
            downloadUrl
            fileType
            fileSize
            images {
              url
              alt
              title
              customData
              ...DatoImageModalFragment
            }
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const resources = printResource.edges

  return (
    <ResourcePage uri={pageData.path}>
      <SEO
        title={pageData.title}
        image={pageData.seoImage.childImageSharp.fixed.src}
      />

      <section className="c-section c-section--resourcesList">
        <div className="container">
          <div className="c-resourcesList">
            <h1>Web Badges</h1>
            <p>
              Making accurate and up-to-date food product data quickly and
              easily available is our goal, and it’s an important one. Show your
              support of Erudus by using one of our web badges.
            </p>
          </div>
        </div>
      </section>

      <section className="c-section c-section--resourcesList">
        <div className="container">
          <div className="c-resourcesList c-resourcesList--print">
            {resources.map(({ node }, i) => (
              <ResourceItem
                key={`ir-print-${i}`}
                title={node.title}
                description={node.descriptionNode.childMarkdownRemark.html}
                fileType={node.fileType}
                fileSize={node.fileSize}
                image={node.images[0]}
                resourceUrl={node.downloadUrl}
                isDownload={node.isDownload}
              />
            ))}
          </div>
        </div>
      </section>
    </ResourcePage>
  )
}

export default ErudusLogosBadgesPage
